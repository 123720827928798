import * as Sentry from "@sentry/browser";

interface SentryInitData {
    dsn: string,
    tracesSampleRate: number,
    replaysSessionSampleRate: number,
    replaysOnErrorSampleRate: number
}

export function initSentry(data: SentryInitData) {
    Sentry.init({
        dsn: data.dsn,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: "0.0.0",
        integrations: [
            Sentry.httpClientIntegration(),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: data.tracesSampleRate,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ["localhost",
            /^\//,
            /^https:\/\/tcaml\.beckhoff\.com/,
            /^https:\/\/tcmlc\.beckhoff\.com/,
            /^https:\/\/mlcreator-dev\.beckhoff-cloud\.com/],


        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: data.replaysSessionSampleRate,
        replaysOnErrorSampleRate: data.replaysOnErrorSampleRate,
    });
}
